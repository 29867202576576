import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'
import color from '../constants/colors'
import { Section, InnerLarge } from './Sections'

const SectionStyled = styled(Section)`
  background-color: ${(props) => (props.bgColor === 'dark' ? color.dark : 'transparent')} !important;
`

const HeadingStyled = styled.h2`
  // border-bottom: 5px solid ${color.main};
  color: ${(props) => (props.bgColor === 'dark' ? 'white' : `${color.heading}`)};
  display: inline-block;
  font-size: 4rem;
  font-weight: lighter;
  text-align: center;

  ${media.lessThan('medium')`
    font-size: 2.6rem;
    line-height: 3.6rem;
  `}

  &:after {
    content: '';
    display: ${(props) => (props.noborder ? 'none' : 'block')};
    width: ${(props) => (props.withoutAnimation ? '100%' : '0')};
    height: 5px;
    background: ${color.main};
    transition: width .7s;
    transition-delay: .3s;

    ${media.lessThan('medium')`
      height: 4px;
    `}
  }

  &.sal-animate::after {
    width: 100%;
  }
`

const InnerLargeStyled = styled(InnerLarge)`
  text-align: center;
  padding: ${(props) => (`${props.paddingY}px 20px ${props.paddingY + 15}px`)};
`

const SectionHeading = ({
  id, className, children, paddingY, bgColor, withoutAnimation,
}) => (
  <SectionStyled id={id} bgColor={bgColor}>
    <InnerLargeStyled paddingY={paddingY}>
      <HeadingStyled
        data-sal
        className={className}
        bgColor={bgColor}
        withoutAnimation={withoutAnimation}
      >
        { children }
      </HeadingStyled>
    </InnerLargeStyled>
  </SectionStyled>
)

SectionHeading.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  paddingY: PropTypes.number,
  bgColor: PropTypes.string,
  withoutAnimation: PropTypes.bool,
}

SectionHeading.defaultProps = {
  id: '',
  className: '',
  children: null,
  paddingY: 80,
  bgColor: '',
  withoutAnimation: false,
}

export default SectionHeading
