import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'

import SectionHeading from '../components/SectionHeading'
import { Section, InnerMedium } from '../components/Sections'

import Layout from '../components/Layout'
import SEO from '../components/seo'

const InnerStyled = styled(InnerMedium)`
  padding-top: 0;
`

const Body = styled.div`
  font-size: 1.6rem;
  text-align: left;

  h2 {
    text-align: center;
  }

  ul {
    list-style: none;
    margin-left: -15px;
  }

  ul li {
    margin-left: -15px;
  }
`

const KiyakuPage = ({ location: { pathname } }) => (
  <StaticQuery
    query={graphql`
      query KiyakuPageQuery {
        markdownRemark(frontmatter: {title: {eq: "利用規約"}}) {
          html
        }
      }
    `}
    render={(data) => (
      <Layout>
        <SEO
          title="利用規約 | ITエンジニア専用のオンライン英会話スクール・Moba Pro IT English（モバプロ）"
          description="モバプロの利用規約ページです。"
          pathName={pathname}
        />
        <SectionHeading withoutAnimation>
          利用規約
        </SectionHeading>
        <Section>
          <InnerStyled>
            <Body dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
          </InnerStyled>
        </Section>
      </Layout>
    )}
  />
)

KiyakuPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      html: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}

KiyakuPage.defaultProps = {
  location: null,
}

export default KiyakuPage
